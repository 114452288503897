import { Modal } from "@/ui/antd";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { RichTextAreaField, SelectField } from "@/components/Fields";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import { getOrderPersonalisations } from "@/utils/Personalisations";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { getTicketDisplayName } from "@/utils/Ticket";
import { required } from "@/utils/Validators";

const messageRequired = required("Message required");
const appliesToRequired = required("Applies to required");

let AddMessageModal = ({
	editingIndex,
	currentEvent,
	ticketMessages,
	visible,
	handleSubmit,
	handleCancel,
	handleSave,
	personalisationsObject = getOrderPersonalisations()
}) => {
	const packagedTickets = currentEvent.event.packagedTickets || [];
	const tickets = currentEvent.event.ticketTypes.concat(packagedTickets);
	const newMessage = editingIndex === null || editingIndex < 0;

	const usedTicketIds = ticketMessages
		? ticketMessages.reduce((ids, msg, index) => {
				if (index === editingIndex) {
					return ids;
				}
				return ids.concat(msg.ticketTypeIds);
		  }, [])
		: [];

	const ticketOptions = tickets
		.filter((ticket) => {
			const used = usedTicketIds.includes(ticket._id);
			return !ticket.deleted && !used;
		})
		.map((ticket) => {
			return { value: ticket._id, label: getTicketDisplayName(ticket) };
		});

	const saveMsg = newMessage ? "Add" : "Update";

	const save = (values, editingIndex) => {
		handleSave(values, editingIndex);
	};

	return (
		<Modal
			open={visible}
			title="Add Message"
			destroyOnClose
			onCancel={handleCancel}
			width={700}
			footer={
				<ButtonsBar footer>
					<LegacyButton key="cancel" onClick={handleCancel} htmlType="button" ariaLabel="Cancel">
						Cancel
					</LegacyButton>
					<LegacyButton
						onClick={handleSubmit((values) => save(values, editingIndex))}
						key="submit"
						type="primary"
						htmlType="submit"
						ariaLabel={saveMsg}
					>
						{saveMsg}
					</LegacyButton>
				</ButtonsBar>
			}
		>
			{visible ? (
				<form onSubmit={handleSubmit((values) => save(values, editingIndex))}>
					<Field
						mode="multiple"
						name="ticketTypeIds"
						label="Applies to"
						placeholder="Please select"
						required
						component={SelectField}
						options={ticketOptions}
						validate={appliesToRequired}
					/>
					<Field
						label="Message"
						name="message"
						placeholder="Thank you for purchasing tickets! See you at the Event!"
						basicEditor
						component={RichTextAreaField}
						minHeight={150}
						personalisations={personalisationsObject}
						validate={messageRequired}
						required
					/>
				</form>
			) : null}
		</Modal>
	);
};
const formName = "addMessageModel";
AddMessageModal = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	destroyOnUnmount: true,
	enableReinitialize: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(AddMessageModal);

export default connect((state, ownProps) => {
	const { ticketMessages, editingIndex } = ownProps;
	const initialValues =
		editingIndex === null || editingIndex < 0
			? { ticketTypeIds: [], message: "", editingIndex }
			: ticketMessages[editingIndex];

	return {
		initialValues,
		currentEvent: state.currentEvent,
		destroyOnUnmount: true,
		enableReinitialize: true
	};
}, {})(AddMessageModal);

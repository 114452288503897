const HEADER_ROW_INDEX = 0;
const CSV_ROW_NUMBER_OFFSET = 2; // offsetting 0-based array index and header row
const ORDER_TICKETS_LIMIT = 100;
const ORDER_LIMIT = 2000;
const REQUIRED_ORDER_FIELDS = ["Buyer First Name", "Buyer Last Name", "Buyer Email"];
const REQUIRED_TICKET_FIELDS = ["Ticket Type"];

const isValidEmail = (email: string) => {
	// eslint-disable-next-line no-useless-escape
	return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
		email
	);
};

const cleanValue = (value: string) => value.replace(/ /g, "").replace(/\r/g, "").toLowerCase();

const getColumnIndex = (headers: string[], requestColumnName: string) => {
	return headers.findIndex((column) => {
		return cleanValue(requestColumnName) === cleanValue(column);
	});
};

export const groupTicketsByOrderId = (csv: string[][]): string[][][] => {
	const headers = csv[0];
	const rows = csv.slice(1);

	const orderIdLoc = getColumnIndex(headers, "Order Id");

	const individualOrderTicket: string[][][] = [];
	const groupedTicketsObject = rows.reduce((groups: { [key: string]: string[][] }, row: string[], index: number) => {
		const orderId: string = row[orderIdLoc];
		const rowNumber = String(index + CSV_ROW_NUMBER_OFFSET);
		const newRow: string[] = [...row, rowNumber];

		const orderIdColumnMissingOrNotProvidedForTicket = orderIdLoc === -1 || orderId === "";
		if (orderIdColumnMissingOrNotProvidedForTicket) {
			individualOrderTicket.push([newRow]);
		} else {
			groups[orderId] = [...(groups[orderId] || []), newRow];
		}
		return groups;
	}, {});

	return [...Object.values(groupedTicketsObject), ...individualOrderTicket];
};

const checkRequiredHeaders = (headers: string[]) => {
	const remainingHeaders = [...REQUIRED_ORDER_FIELDS, ...REQUIRED_TICKET_FIELDS];
	headers.forEach((header) => {
		const index = getColumnIndex(remainingHeaders, header);
		if (index > -1) {
			remainingHeaders.splice(index, 1);
		}
	});
	return remainingHeaders.length ? remainingHeaders.map((header) => `Required column ${header} is missing`) : [];
};

const checkOrderFields = (
	order: string[][],
	headers: string[],
	orderIdIndex: number,
	requiredHeadersIndexes: number[]
) => {
	const firstTicket = order[0];
	const orderId = firstTicket[orderIdIndex];
	const errorHeaders: string[] = [];
	for (const index of requiredHeadersIndexes) {
		const uniqueNonEmptyValuesForHeader = order.reduce((uniqueVals, ticket) => {
			const val = ticket[index];
			if (val != "") {
				uniqueVals.add(val);
			}
			return uniqueVals;
		}, new Set());

		if (uniqueNonEmptyValuesForHeader.size !== 1) {
			errorHeaders.push(headers[index]);
		}
	}

	if (errorHeaders.length) {
		const rowNumberInOrderTicketIndex = firstTicket.length - 1;
		const rowNumber = firstTicket[rowNumberInOrderTicketIndex];
		return [
			`${
				orderId ? `Order Id ${orderId}` : `Row ${rowNumber}`
			} doesn't contain a unique value for mandatory order fields ${errorHeaders.join(", ")}.`
		];
	}
	return [];
};

const checkOrderTicketLimit = (order: string[][], orderIdIndex: number) => {
	const firstTicket = order[0];
	const orderId = firstTicket[orderIdIndex];
	return order.length > ORDER_TICKETS_LIMIT
		? [`Order Id ${orderId} has exceeded the limit of tickets ${ORDER_TICKETS_LIMIT}.`]
		: [];
};

const checkOrderLimit = (orders: string[][][]) => {
	return orders.length > ORDER_LIMIT ? [`The number of orders has exceeded the limit of ${ORDER_LIMIT}.`] : [];
};

const checkOrders = (orders: string[][][], headers: string[]) => {
	const orderIdIndex = getColumnIndex(headers, "Order Id");
	const requiredHeadersIndexes = headers.reduce((headerLocations: number[], header: string, index: number) => {
		const foundIndex = getColumnIndex(REQUIRED_ORDER_FIELDS, header);
		if (foundIndex > -1) {
			headerLocations.push(index);
		}
		return headerLocations;
	}, []);

	return orders.reduce((errors: string[], order: string[][]) => {
		return [
			...errors,
			...checkOrderFields(order, headers, orderIdIndex, requiredHeadersIndexes),
			...checkOrderTicketLimit(order, orderIdIndex)
		];
	}, []);
};

const checkTicketTypes = (rows: string[][], headers: string[], ticketTypeNames: string[]) => {
	const errors: string[] = [];
	const nameLoc = getColumnIndex(headers, "tickettype");
	rows.forEach((row, index) => {
		const ticketTypeValue = row[nameLoc];
		const rowNumber = index + CSV_ROW_NUMBER_OFFSET;
		if (!ticketTypeValue) {
			errors.push(`Row ${rowNumber} is missing the required ticket type field.`);
		} else if (!ticketTypeNames.find((name) => name.trim() === ticketTypeValue.trim())) {
			errors.push(`Row ${rowNumber} contains a ticket type which doesn't exist: ${ticketTypeValue}`);
		}
	});
	return errors;
};

const checkEmailValid = (rows: string[][], headers: string[]) => {
	const emailLoc = getColumnIndex(headers, "buyeremail");
	const invalidEmailErrors: string[] = [];
	rows.forEach((row, index) => {
		const email = row[emailLoc];
		const emailInvalid = email && !isValidEmail(email.trim());
		if (emailInvalid) {
			const rowNumber = index + CSV_ROW_NUMBER_OFFSET;
			invalidEmailErrors.push(`Row ${rowNumber} contains an invalid email ${email}`);
		}
	});
	return invalidEmailErrors;
};

const checkEmptyRows = (rows: string[][]) => {
	const emptyRows: number[] = [];
	const isRowEmpty = (row: string[]) => !row.some((val) => val);
	rows.forEach((row, index) => {
		if (isRowEmpty(row)) {
			const rowNumber = index + CSV_ROW_NUMBER_OFFSET;
			emptyRows.push(rowNumber);
		}
	});
	return emptyRows.length ? [`Rows ${emptyRows.join(", ")} are empty.`] : [];
};

export const checkCsvIsValid = (csv: string[][], ticketTypeNames: string[]) => {
	if (!csv.length) return ["The provided csv file is empty"];

	const headers = csv[HEADER_ROW_INDEX];
	const rows = [...csv];
	rows.splice(HEADER_ROW_INDEX, 1);

	if (!rows.length) return ["The csv file must have at least a single row of fields"];

	const requiredHeaderErrors = checkRequiredHeaders(headers);
	if (requiredHeaderErrors.length) return requiredHeaderErrors;

	const emptyRowsErrors = checkEmptyRows(rows);
	if (emptyRowsErrors.length) return emptyRowsErrors;

	const orders = groupTicketsByOrderId(csv);
	const ordersLengthErrors = checkOrderLimit(orders);
	if (ordersLengthErrors.length) return ordersLengthErrors;

	return [
		...checkTicketTypes(rows, headers, ticketTypeNames),
		...checkEmailValid(rows, headers),
		...checkOrders(orders, headers)
	];
};

export const getOrderFieldValue = (orderTickets: string[][], index: number) => {
	const orderContainingFieldValue = orderTickets.find((ticket) => {
		return ticket[index] !== "";
	});

	return orderContainingFieldValue ? orderContainingFieldValue[index].trim() : "";
};

export const getOrderFieldValueFromColumnName = (orderTickets: string[][], headers: string[], columnName: string) => {
	const columnIndex = getColumnIndex(headers, columnName);
	return columnIndex > -1 ? getOrderFieldValue(orderTickets, columnIndex) : "";
};

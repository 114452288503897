const NEXUS_STATES = [
	"AL",
	"AR",
	"AZ",
	"CA",
	"CO",
	"CT",
	"DC",
	"FL",
	"GA",
	"HI",
	"IA",
	"ID",
	"IL",
	"IN",
	"KA",
	"KS",
	"KY",
	"LA",
	"MA",
	"MD",
	"ME",
	"MI",
	"MN",
	"MO",
	"NC",
	"ND",
	"NE",
	"NJ",
	"NM",
	"NV",
	"NY",
	"OH",
	"OK",
	"PA",
	"RI",
	"SC",
	"TN",
	"TX",
	"UT",
	"VA",
	"VT",
	"WA",
	"WI"
];

export const isEventInNexusState = (
	eventLocation: { type?: string; addressComponents?: { short_name: string }[] },
	location: string
) => {
	if (location !== "US") return false;
	return (
		eventLocation?.type === "address" &&
		(eventLocation?.addressComponents || []).some((component) => {
			return NEXUS_STATES.includes(component.short_name);
		})
	);
};
